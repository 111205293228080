.dashboard-loader {
 position: relative;
 display: block;
 //   justify-content: center;
 //   align-items: center;

 .ring {
  position: absolute;
  height: 30px;
  width: 30px;
  border: 0px solid #272727;
  border-radius: 50%;

  &:nth-child(1) {
   border-bottom-width: 5px;
   border-color: #00c7ba;
   animation: rotate1 1.5s linear infinite;
  }

  &:nth-child(2) {
   border-right-width: 5px;
   border-color: #3a67e1;
   animation: rotate2 1.5s linear infinite;
  }

  &:nth-child(3) {
   border-top-width: 5px;
   border-color: #ef365c;
   animation: rotate3 1.5s linear infinite;
  }
 }
}

@keyframes rotate1 {
 0% {
  transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
 }
 100% {
  transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
 }
}

@keyframes rotate2 {
 0% {
  transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
 }
 100% {
  transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
 }
}

@keyframes rotate3 {
 0% {
  transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
 }
 100% {
  transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
 }
}
