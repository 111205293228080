body {
 background: url("../../images/pattern.svg");
}
/* Start::basic sizes */
.fs-sm {
 font-size: 0.6875rem; //11px
}
.fs-base {
 font-size: 0.875rem; //14px
}
.fs-lg {
 font-size: 1.125rem; //18px
}
/* End::basic sizes */

/* Start::font sizes */
// (1px-30px are linear, above 30px are step increment of 1)
.fs-1 {
 font-size: 0.0625rem; //1px
}
.fs-2 {
 font-size: 0.125rem; //2px
}
.fs-3 {
 font-size: 0.1875rem; //3px
}
.fs-4 {
 font-size: 0.25rem; //4px
}
.fs-5 {
 font-size: 0.3125rem; //5px
}
.fs-6 {
 font-size: 0.375rem; //6px
}
.fs-7 {
 font-size: 0.4375rem; //7px
}
.fs-8 {
 font-size: 0.5rem; //8px
}
.fs-9 {
 font-size: 0.5625rem; //9px
}
.fs-10 {
 font-size: 0.625rem !important; //10px
}
.fs-11 {
 font-size: 0.6875rem; //11px
}
.fs-12 {
 font-size: 0.75rem; //12px
}
.fs-13 {
 font-size: 0.8125rem !important; //13px
}
.fs-14 {
 font-size: 0.875rem !important; //14px
}
.fs-15 {
 font-size: 0.9375rem !important; //15px
}
.fs-16 {
 font-size: 1rem !important; //16px
}
.fs-17 {
 font-size: 1.0625rem; //17px
}
.fs-18 {
 font-size: 1.125rem !important; //18px
}
.fs-19 {
 font-size: 1.1875rem; //19px
}
.fs-20 {
 font-size: 1.25rem !important; //20px
}
.fs-21 {
 font-size: 1.3125rem; //21px
}
.fs-22 {
 font-size: 1.375rem; //22px
}
.fs-23 {
 font-size: 1.4375rem; //23px
}
.fs-24 {
 font-size: 1.5rem; //24px
}
.fs-25 {
 font-size: 1.5625rem !important; //25px
}
.fs-26 {
 font-size: 1.625rem !important; //26px
}
.fs-27 {
 font-size: 1.6875rem !important; //27px
}
.fs-28 {
 font-size: 1.75rem; //28px
}
.fs-29 {
 font-size: 1.8125rem; //29px
}
.fs-30 {
 font-size: 1.875rem !important; //30px
}
.fs-32 {
 font-size: 2rem; //32px
}
.fs-34 {
 font-size: 2.125rem; //34px
}
.fs-36 {
 font-size: 2.25rem; //36px
}
.fs-38 {
 font-size: 2.375rem; //38px
}
.fs-40 {
 font-size: 2.5rem; //40px
}
.fs-50 {
 font-size: 3.125rem !important; //50px
}
/* End::font sizes */

.inline-start {
 float: inline-start;
}
.inline-end {
 float: inline-end;
}
.text-blue {
 color: #0d6efd;
}
.error {
 color: #d90404;
 font-size: 12.8px;
 margin: 5px 0;
}
.inline-block {
 display: inline-block;
}
.col-lg-4 {
 float: left;
}
.col-lg-2 {
 float: left;
}
.col-lg-3 {
 float: left;
}
.col-lg-10 {
 float: left;
}
.col-lg-6 {
 float: left;
}
.d-rubytext {
 display: ruby-text;
}
h1,
h2,
h3,
h4,
h5,
h6 {
 position: relative;
 font-weight: normal;
 margin: 0rem;
 background: none;
 line-height: 1.6em;
 font-family: "Montserrat", sans-serif;
}
