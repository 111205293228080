/*Modal*/
.modal-front {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background: rgba(0, 0, 0, 0.6);
 z-index: 1111;
}

.modal-main-front {
 position: fixed;
 background: $clr-white;
 width: 55%;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 padding: 0.9375rem 0 1.5625rem;
 border-radius: 0.625rem;
 max-height: 35rem;
 overflow-y: scroll;
}

.modal-main-front.popup-submit {
 position: fixed;
 background: $clr-white;
 width: 54.2%;
 height: auto;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 padding: 0rem 0 1.25rem;
 border-radius: 0.625rem;
 max-height: auto;
 overflow-y: unset;
 max-height: 36.875rem;
 -ms-overflow-style: none;
 /* for Internet Explorer, Edge */
 scrollbar-width: none;
 /* for Firefox */
 overflow-y: scroll;
}

.modal-main-front.popup-submit::-webkit-scrollbar {
 display: none;
 /* for Chrome, Safari, and Opera */
}

.display-block-front {
 display: block;
}

.display-none-front {
 display: none;
}

.modal-box {
 padding: 0.3125rem 5rem 0rem 1.5625rem;
}

.sign-border-front {
 border-top: 0.0625rem solid $clr-pink;
 padding: 0.1875rem 0.9375rem;
 width: 14%;
 margin-top: 0rem;
}

h2.sign-heading-front {
 color: $clr-blue;
 font-family: $font-family-montserrat;
 font-size: 1.5625rem;
 padding-top: 1.25rem;
 font-weight: $font-bold;
}

h2.get-started-front {
 color: $clr-black;
 font-family: $font-family-opensans;
 font-size: 1.125rem;
 font-weight: $font-regular;
 margin-top: 0rem;
 margin-bottom: 1.875rem;
}

.signup-container {
 padding: 0 3.4375rem;
}

.btn-mobile-front {
 color: rgb(255, 255, 255);
 font-weight: $font-bold;
 text-transform: none;
 font-size: 1.0625rem;
 height: 2.625rem !important;
 border: 0rem;
 border-radius: 1.5625rem;
 padding: 0 0.9375rem;
 cursor: pointer;
 margin: 0 auto;
 /* box-shadow: rgb(239 54 92) 0rem 0.875rem 0.5rem -0.8125rem; */
 /* min-width: 8.125rem; */
 font-family: $font-family-opensans;
 line-height: 1.625rem;
}

.btn-mobile-front:hover {
 background-color: $clr-green;
 box-shadow: none !important;
}

.allcancel-front {
 float: right;
 background-color: $clr-white;
 padding: 0.3125rem 0.3125rem 0rem;
 border-radius: 0.5rem;
 line-height: 0;
}

.cancelgrid-front {
 text-align: right;
 margin: 0rem 0.0625rem 0 !important;
}

button.cancel-btn {
 background-color: $clr-white;
 color: $clr-bg-advisor;
 cursor: pointer;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.signup-logo {
 margin-top: 3.4375rem;
 margin-left: 1.25rem;
 margin-bottom: 0.625rem;
}

.logotp-popup {
 margin-top: 2.5rem;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.mb-icon.passwordicon-top-front {
 margin: 0.5rem 1.5625rem 0rem 0rem !important;
}

.passwordicon-signintop {
 margin: -0.125rem 0.9375rem 0rem 0rem !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.backicon {
 font-size: 1.375rem;
}

button.cancel-btn.mv-back {
 margin: 0.5rem -0.3125rem;
}

img.mb-dlogo.deseo-logo-popup {
 margin-left: 0.5rem;
 width: 15%;
 margin-top: 0.125rem;
}
.account-text-front {
 font-family: "Montserrat", sans-serif !important;
 font-size: 1.1875rem;
 text-align: center;
 color: $clr-text-seven;
 font-weight: $font-medium;
 margin-bottom: 1rem;
}

.signup-pass-front {
 padding-top: 1rem !important;
}
.cancel-mar {
 margin: 0.25rem 1rem 0rem 0;
}

button.cancel-btn.mv-back.subplanarrow {
 margin: 0.25rem 0.75rem 0;
}
.accagrement
 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standardError.MuiAlert-standard {
 margin: 0px 0 15px 0 !important;
}
.agreementPreview {
 overflow-y: scroll;
 height: 380px;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.signup-container.accagrement {
 box-shadow: $clr-top-box-shadow;
 position: relative;
}
.agrementtimer {
 padding-top: 16px;
}
section.modal-main-front.popup-submit.risk-profile.accagrement {
 overflow-y: unset !important;
 max-height: 42rem;
}
button.btn.btn-1.hover-filled-slide-right.btn-mobile-front.mb-20.accagrement {
 margin: 0px 15px 0 !important;
}
button.cancel-btn.mv-back.mobile {
 margin: 0.5rem 1.5625rem 0;
}
.account-bal {
 font-size: 0.9375rem;
 font-family: $font-family-opensans;
 line-height: 1.5rem;
 font-weight: $font-medium;
 text-align: right;
 padding: 0.1875rem 0.9375rem;
 color: $clr-bg-portfolio;
 margin-top: 1.25rem;
}

.account-value {
 font-size: 0.9375rem;
 font-family: $font-family-opensans;
 line-height: 1.5rem;
 font-weight: $font-bold;
 text-align: left;
 padding: 0.1875rem 0.3125rem;
 color: $clr-bg-portfolio;
 margin-top: 1.25rem;
 margin-bottom: 0.625rem;
}

.accval-border {
 border: 0.0625rem solid #b9b9b9;
 border-radius: 0.1875rem;
 padding: 0.1875rem 0.3125rem;
 margin-left: 0.3125rem;
}

.cross-font {
 font-size: 0.8125rem;
}

button.account-value {
 background: $clr-white;
 padding: 0.1875rem 0 0.1875rem 1.25rem;
 font-weight: $font-regular;
 cursor: pointer;
}

button.account-value:hover {
 background-color: $clr-white;
 box-shadow: unset !important;
}

.investport-head {
 margin-top: 3.75rem !important;
}

.actual-price {
 font-size: 0.875rem;
 font-family: $font-family-opensans;
 font-weight: $font-medium;
 text-align: center;
 line-height: 1.5rem;
}

.margin-auto {
 margin: 0 auto;
}

.investing-grid {
 background-color: $clr-green;
 border-top-left-radius: 0.3125rem;
 border-top-right-radius: 0.3125rem;
 padding: 0 2.5rem;
}

p.invest-head {
 font-size: 0.9375rem;
 font-family: $font-family-opensans;
 font-weight: $font-bold;
 line-height: 1.5rem;
 color: $clr-white;
 margin: 0.625rem 0rem 0.625rem -0.625rem;
}

p.invest-subhead {
 font-size: 0.9375rem;
 font-family: $font-family-opensans;
 font-weight: $font-medium;
 line-height: 1.5rem;
 color: $clr-bg-portfolio;
 margin: 0.625rem 0;
 padding: 0 1.875rem;
}

.invest-border {
 border: 0.0625rem solid $clr-border-one;
 border-bottom-left-radius: 0.3125rem;
 border-bottom-right-radius: 0.3125rem;
 border-top: 0rem;
 max-height: 11.25rem;
 overflow-y: scroll;
 padding: 0 0rem;
 border-right: 0;
}

.invest-subhead-border {
 border-bottom: 0.0625rem solid $clr-border-one;
 height: fit-content;
}

.invest-subhead-border:last-child {
 border-bottom: none;
}

.invest-mtb {
 margin-top: -1.25rem !important;
 margin-bottom: 1.25rem !important;
}
.invest-container {
 background: linear-gradient(to right, $clr-blue 0%, $clr-blue 50%, $clr-pink 50%, $clr-pink 100%);
 // display: flex;
 display: block;
 justify-content: center;
 align-items: center;
 /* max-width: 118.9375rem; */
}

.hide {
 display: none;
}

h2.risk-profile-ques {
 color: $clr-blue;
 font-family: $font-family-montserrat;
 font-size: 1.5625rem;
 margin-top: 2.375rem;
 font-weight: $font-bold;
 text-align: center;
}

button.btn:disabled {
 box-shadow: none !important;
}

h2.total-ques {
 color: $clr-blue;
 font-family: $font-family-montserrat;
 font-size: 1.1875rem;
 font-weight: $font-regular;
 margin-top: 0rem;
 margin-bottom: 0.625rem;
 padding-top: 0.3125rem;
}

.risk-ques-border {
 border-top: 0.0625rem solid $clr-pink;
 padding: 0.3125rem 0.9375rem;
 width: 11%;
 margin-top: 0.625rem;
}

span.bold-number {
 font-weight: $font-semibold;
}

span.MuiRadio-root.MuiRadio-colorPrimary.MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.PrivateSwitchBase-root.Mui-checked {
 color: $clr-blue;
}

h2.question-risk {
 font-size: 1.125rem;
 font-weight: $font-bold;
 margin: 1.25rem 0 0.9375rem;
 font-family: $font-family-opensans;
}

.modal-main-front.popup-submit.risk-profile {
 width: 54.2%;
}

.d-grid {
 display: -ms-grid;
 display: grid;
}

.riskprofile-container {
 padding: 0 1.25rem;
}

img.img-width {
 width: 60%;
 cursor: pointer;
}

.vline3 {
 border-left: 0.0625rem solid $clr-border-one;
 height: 3.125rem;
 position: absolute;
 left: 49.6%;
 margin-top: 0rem;
}

.broker-margin {
 /* margin: 2.5rem 0rem 3.125rem -1.25rem; */
 /* margin: 2.5rem 0; */
 padding: 1.25rem 0 5em;
}
.broker-margin img.img-width {
 cursor: pointer;
}
.m20 {
 margin: 1.25rem !important;
}

label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.question-label
 span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
 font-size: 1.125rem;
 font-family: $font-family-opensans;
 font-weight: $font-regular;
 line-height: 1.5rem;
}

.risk-question {
 text-align: center;
}

span.bold {
 font-weight: bold;
}

.risk-question-text {
 display: -moz-inline-stack;
 display: inline-block;
 zoom: 1;
 *display: inline;
 text-align: left;
}

fieldset.MuiOutlinedInput-notchedOutline {
 box-shadow: none !important;
 border-radius: 0.25rem !important;
 border: 0.0625rem solid #b9b9b9 !important;
}

h2.risk-complete {
 color: $clr-hyperlink;
 font-family: $font-family-montserrat;
 font-size: 1.125rem;
 font-weight: $font-regular;
 /* margin-top: -0.125rem; */
 margin-bottom: 0.625rem;
 /* padding-top: 0rem; */
 line-height: 1.5rem;
}

p.congr-text {
 font-size: 1.125rem;
 font-family: $font-family-opensans;
 line-height: 1.5rem;
 margin-top: 1.5625rem;
 font-weight: $font-medium;
}

p.congr-text1 {
 font-size: 1.125rem;
 font-family: $font-family-opensans;
 line-height: 1.5rem;
 font-weight: bold;
 margin-top: 1.5625rem;
}

.sub-plan {
 font-family: $font-family-opensans;
 font-size: 0.9375rem;
 font-weight: $font-semibold;
 text-align: left;
 margin: 0.625rem 0 0rem;
 line-height: 2.1875rem;
}

.plan-price {
 text-align: right;
 padding: 0 0.625rem 0rem 0;
 line-height: 2.1875rem;
}
s.strike-price {
 font-size: 12px;
 padding-right: 10px;
}
.border-splan {
 border-bottom: 0.0625rem solid $clr-border-one;
 width: 75%;
 margin: -0.5rem auto !important;
}

.subscription-container {
 padding: 0 0;
}

.passwordicon-front {
 margin: -0.125rem 1.5625rem 0rem 0rem !important;
}

button.btn:hover {
 background-color: $clr-green;
 box-shadow: none !important;
}

.backicon {
 color: $clr-bg-advisor !important;
 cursor: pointer;
}

.backarrow-front {
 margin-top: -2em !important;
 padding: 0rem 0.5rem 0;
}

.cancelicon {
 color: $clr-bg-advisor !important;
}

a.verifysignup-link {
 text-decoration: none;
 font-weight: $font-semibold;
 color: $clr-hyperlink;
}

.reset-pass {
 color: $clr-text-seven;
 font-family: $font-family-opensans;
 font-size: 1.1875rem;
 font-weight: $font-regular;
 margin-top: 0rem;
 margin-bottom: 1.875rem;
}

// img.mb-gwlogo {
// 	width: 12.5rem;
// }

p.portdetails-answer ol {
 /* margin-top: -0.5rem;
    margin-bottom: -0.3125rem; */
 counter-reset: item;
 color: $clr-text-five;
 padding-left: 2rem;
}

p.portdetails-answer ol li {
 list-style-type: decimal;
 display: block;
}

p.portdetails-answer ol li:before {
 content: counter(item) ". ";
 counter-increment: item;
 font-weight: bold;
 color: $clr-text-five;
 width: 1.25rem;
}

button.theme-btn.btn-style-one.get-started:hover {
 background-color: $clr-white !important;
 box-shadow: rgba(255, 255, 255, 0) 0rem 0.8125rem 0.625rem -0.875rem !important;
}

p.nonedit-text {
 color: $clr-error;
 font-family: $font-family-opensans;
 font-size: 0.875rem;
 font-weight: $font-regular;
 margin: -0.9375rem 0rem 0.625rem !important;
}
span.email-overflow {
 display: inline-block;
 width: 14.375rem;
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
 position: absolute;
 margin-left: 0.625rem !important;
}

.portdetails-risk {
 margin-top: 0rem;
 width: 73%;
 position: relative;
}

p.term-text-front {
 font-size: 1.03125rem;
 margin-left: -0.75rem;
 line-height: 1.4em;
 color: $clr-text-seven;
 margin-top: 0.5625rem;
 font-weight: $font-regular;
 font-family: "Montserrat", sans-serif !important;
}

.term-policy-link {
 font-size: 1.03125rem;
 color: $clr-hyperlink;
 text-decoration: underline !important;
 font-family: "Montserrat", sans-serif !important;
}

.paddTop-0 {
 padding-top: 0 !important;
}

.MuiFormHelperText-root.Mui-error {
 margin-top: 0.625rem !important;
 font-size: 0.875rem !important;
 color: $clr-error !important;
 line-height: 1.2;
 margin-left: 0rem;
}

a.fplink-front {
 text-align: right;
 line-height: 1;
 font-weight: $font-regular;
 font-size: 1.1875rem;
 color: $clr-pink;
 margin: 0rem;
 font-family: "Montserrat", sans-serif !important;
}

.alert-top {
 padding-left: 0.625rem;
}

.MuiTypography-root.MuiTypography-body1.MuiTypography-gutterBottom.otp-front {
 text-align: center;
 line-height: 1;
 font-weight: $font-semibold;
 font-size: 0.875rem;
 color: $clr-pink;
 margin: 0rem;
 font-family: "Montserrat", sans-serif !important;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.mtop-40 {
 margin-top: 2.5rem;
}

.otpinput-front input {
 border: 0;
 border-bottom: 0.0625rem solid $clr-border-one;
 margin: 1.25rem 0.6875rem 0.3125rem;
 text-align: center;
 padding: 0.3125rem 0.6875rem 0.3125rem;
 font-size: 1.1875rem;
 /* box-shadow: rgb(139 139 139) 0rem 0.8125rem 1.125rem -0.9375rem; */
 color: $clr-blue;
 font-weight: $font-regular;
 width: 3rem !important;
 color: $clr-bg-portfolio;
 font-family: "Montserrat", sans-serif !important;
}

p.otp-text-front {
 font-family: $font-family-opensans;
 font-size: 0.875rem;
 margin-top: 0rem;
 text-align: center;
 color: $clr-text-eight;
 font-weight: $font-regular;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standard.alertmsg {
 margin: 0.9375rem 0 0 0.625rem;
 font-family: $font-family-montserrat;
 font-size: 0.9375rem;
 font-weight: $font-semibold;
}

h2.verifysignup-heading-front {
 color: $clr-pink;
 font-family: $font-family-opensans;
 font-size: 1.5rem;
 font-weight: $font-bold;
 text-align: center;
}

p.verifysignup-msg-front {
 font-family: $font-family-opensans;
 font-size: 1rem;
 margin: 0.3125rem;
 font-weight: $font-regular;
 line-height: 1.2;
 text-align: center;
}

.mobile-border-front {
 border-top: 0.0625rem solid $clr-border-three;
 margin: 0.625rem 0.3125rem 1.875rem 0.75rem;
 width: 43%;
}

.deseo-logo-popup {
 // width: 20%;
 display: none;
}

.paddtop-8 {
 padding-top: 0.5rem;
}

.success-icon {
 font-size: 1.5rem;
 margin: 0rem 1rem 0rem 0rem;
 color: $clr-success;
 float: right;
}

.error-icon {
 font-size: 1.5rem;
 margin: 0rem 1rem 0rem 0rem;
 color: $clr-red;
 float: right;
}
.coupon-mt {
 margin-top: 40px;
}
a.nobtn {
 line-height: 2.625rem;
}

.pricingBtn {
 width: 100%;
 display: inline-block;
}
span.signmenu {
 position: relative;
 display: block;
 color: #ffffff;
 text-align: center;
 line-height: 0.625rem;
 letter-spacing: 0rem;
 opacity: 1;
 font-weight: 500;
 padding: 2.125rem 0rem;
 font-size: 1rem;
 text-decoration: none;
}

p.popuperror {
 margin: 4em 40px 3em;
 font-size: 18px;
 font-weight: 500;
 text-align: center;
}

.otpinput {
 margin: 0% auto;
 text-align: center;
}

.otpgrid {
 width: 420px;
 margin: 0 auto !important;
}

.otpinput input[type="text"] {
 border: 0 !important;
 border: 1px solid #3a67e1 !important;
 margin: 25px 1px 20px 3px;
 text-align: center;
 padding: 5px 5px 5px;
 font-size: 19px;
 color: #434343; /* Note: This will override the previous color */
 font-weight: 400;
 font-family: "Montserrat", sans-serif;
 width: 3rem !important;
 border-radius: 8px;
}

.password-icon {
 /* position: relative;
      margin-left: 13.5em !important; */
 font-size: 24px;
 /* margin-top: -1.7em !important;
      float: inline-start; */
 color: #3a67e1;
 cursor: pointer;
}
.password-div {
 float: inline-end;
 position: relative;
 margin-top: -2.5em;
 margin-right: 3em;
}

.tooltip .tooltiptext {
     background-color: #13689b;
     border-radius: 6px;
     bottom: 100%;
     color: #fff;
     font-family: Poppins, sans-serif;
     font-size: 12.5px;
     font-weight: 500;
     left: 50%;
     line-height: 1.2;
     margin-left: 0em;
     padding: 10px;
     position: absolute;
     text-align: center;
     visibility: hidden;
     width: 340px;
     z-index: 1;
    }
    a.tooltip {
     border: 1px solid #13689b !important;
     border-radius: 50px;
     color: #13689b;
     display: inline-block;
     font-size: 16px !important;
     font-weight: 700;
     margin-left: 6px;
     padding: 0 !important;
     position: relative;
    }
    .tooltip:hover .tooltiptext {
     visibility: visible;
    }

.withdraw-input-container {
     margin: 0 auto !important;
     display: flex;
     align-items: center;
  }
  
  input.withdraw-input {
     background-color: #f1f1f1;
     border-radius: 5px;
     color: #091e34;
     display: block;
     font-family: "Poppins", sans-serif;
     font-weight: 500;
     height: 40px;
     margin-top: 14px;
     margin-bottom: 14px;
     padding: 10px 15px;
     width: 100%;
     font-size: 1rem;
  }
  
  .withdraw-rupee-symbol {
     margin-right: 4px;
     font-size: 20px;
     font-weight: 500;
  }
  
  a.tooltip.withdraw-tooltip {
     font-size: 15.8px !important;
     font-weight: 550 !important;
     margin-top: -18px;
  }
  
  .withdrawal-tooltiptext {
     width: 240px !important;
  }
  
  .broker-stack {
     margin: 2em 0em 1em 0em !important;
  }
  
  
  @media (min-width: 320px) and (max-width: 480px) {
     .withdrawal-tooltiptext {
        margin-left: -18em !important;
        margin-bottom: 0.5rem;  
     } 
  }