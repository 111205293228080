.main-content {
 margin-inline-start: 16rem;
 transition: all 50ms ease;
}
.pagination-rows-counts {
 display: "inline-flex";
 flex: 2;
 font-size: 0.875rem;
}

.main-content.inactive {
 margin-inline-start: 5rem;
 background-color: rgba(234, 237, 247, 0.08);
}

.card.custom-card {
 border-radius: 0.688rem;
 border: 0;
 background-color: #ffffff;
 // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
 box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
 position: relative;
 margin-block-end: 1.25rem;
 width: 100%;
}
// .card.custom-card.form {
//   background-color: #f8f8f8;
// }
.card.custom-card.tab {
 min-height: 100vh;
 overflow: scroll;
}
.card-header {
 background-color: #fff !important;
}
h2.main-content-title {
 font-size: 22px;
 // color: #3a67e1;
 background-color: #3a67e1;
 background-clip: text;
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
 font-weight: 700;
 font-family: "Montserrat", sans-serif !important;
}
.main-content-label {
 font-weight: 800;
 font-size: 15px;
 margin-block-end: 0;
 line-height: 1;
}
.download {
 -webkit-box-align: center;
 align-items: center;
 background-color: rgba(16, 185, 129, 0.12);
 border-radius: 12px;
 color: rgb(11, 129, 90);
 cursor: pointer;
 display: inline-flex;
 flex-direction: column;
 -webkit-box-flex: 0;
 flex-grow: 0;
 flex-shrink: 0;
 line-height: 2;
 font-weight: 600;
 -webkit-box-pack: center;
 justify-content: center;
 letter-spacing: 0.5px;
 padding-left: 8px;
 padding-right: 8px;
 text-transform: capitalize;
 white-space: nowrap;
}
.report-icon {
 width: 40px;
 height: 40px;
 font-size: 15px !important;
 border-radius: 50px;
 line-height: 40px !important;
 display: flex !important;
 align-items: center;
 justify-content: center;
 color: #fff;
 background-color: #dee7ff;
}
.report-icon i {
 color: #3a67e1;
 font-size: 20px;
}
.report-head {
 position: relative;
 //  right: 1.8em;
}
.card-item .card-item-icon {
 width: 35px;
 height: 35px;
 position: absolute;
 inset-inline-end: 9px;
 inset-block-end: 46px;
 inset-block-start: auto;
 border-radius: 50%;
 display: flex;
 align-items: center;
 justify-content: center;
}
.num-icon {
 width: 35px;
 height: 35px;
 font-size: 22px !important;
 border-radius: 50px;
 line-height: 40px !important;
 display: flex !important;
 align-items: center;
 justify-content: center;
 margin-top: 5px;
 margin-left: -10px;
}
.num-icon.green {
 color: #00c7ba;
 background-color: rgba(0, 199, 186, 0.2);
}
.num-icon.blue {
 color: #3a67e1;
 background-color: #dee7ff;
}
.num-icon.pink {
 background-color: rgba(239, 54, 92, 0.18);
 color: #ef365c;
}
.clr-gradient {
 // background: -webkit-linear-gradient(#fccaca, #f70000);
 // background-clip: text;
 // -webkit-background-clip: text;
 // -webkit-text-fill-color: transparent;
 color: #333;
}
.bi-person-circle::before {
 content: "\f4d7";
 background: -webkit-linear-gradient(#fccaca, #f70000);
 background-clip: text;
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
}
.dash-revenue {
 background-color: #71c1dc;
 border-radius: 0.688rem;
}
.mrl-auto {
 margin: 0 auto;
}
button.btn1.btn-primary.btn-1 {
 color: #ffffff;
 border: 0;
 // font-size: 14px;
 font-size: 15px;
 font-weight: 500;
 background-color: #3a67e1;
 // padding: 6px 15px;
 padding: 10px 22px;
 border-radius: 8px;
 //  display: flex;
 //  margin: 0 auto;
}
button.btn1.btn-primary.btn-2 {
 color: #ffffff;
 border: 0;
 font-size: 13px;
 font-weight: 500;
 background-color: #2f5afa;
 padding: 10px 13px;
 border-radius: 8px;
 display: flex;
}
.list-card {
 background-color: rgba(104, 89, 202, 0.05);
 padding: 5px 18px;
 border-radius: 11px;
 margin-block-end: 6.5px;
 position: relative;
 color: rgba(33, 37, 41, 0.75);
}
.head-black {
 color: #000;
}
.head-pink {
 color: #ef365c;
}
// .js-plotly-plot .plotly, .js-plotly-plot .plotly div{
//     margin: -0.6em 0px 0px -10px !important;
//     display: flex;
// }
.modebar.modebar--hover.ease-bg {
 display: none;
}
// .js-plotly-plot {
//  height: 260px;
//  width: 350px;
// }
.card-item-stat {
 height: 30px;
}
ul.MuiList-root.MuiList-padding.MuiMenu-list {
 padding: 0.3125rem 0.625rem !important;
 // background-color: #ffffff;
}
li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
 // color: #2a242e;
 padding: 0.5rem 0.5rem 0.5rem;
}
.MuiFormControl-root.pagination
 .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl
 .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
 border: 1px solid #b9b9b9 !important;
}
.table-pagination {
 margin-top: 29px;
}
.btn1 {
 display: inline-block;
 padding: 0.25rem 0.5rem;
 font-size: 0.875rem;
 font-weight: 400;
 line-height: 1.2;
 margin: 0 3px;
 color: #3a67e1;
 text-align: center;
 text-decoration: none;
 vertical-align: middle;
 cursor: pointer;
 -webkit-user-select: none;
 user-select: none;
 border: 1px solid #3a67e1;
 border-radius: 0.25rem;
 background-color: #fff;
 transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
}
.btn1:hover {
 background-color: #3a67e1;
 color: #fff;
 box-shadow: none !important;
}
ol.advisor li::before {
 width: 0em;
 margin-left: -2px;
 font-weight: 500;
 margin-right: 2px;
}
ol.breadcrumb.mb-4.advisor {
 margin-top: -6px;
}
.form-control {
 // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
 box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
 border: 0px !important;
 height: 50px;
 border-radius: 8px !important;
 font-family: "Montserrat", sans-serif !important;
}
.form-control:focus {
 // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
 box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
}
.form-select {
 // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
 box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
 border: 0px !important;
 height: 50px;
 border-radius: 8px !important;
}
.form-select:focus {
 // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
 box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
}
label.headings {
 font-size: 15px;
 font-weight: 500;
 margin: 10px 0;
}
.risk-ques {
 text-align: left;
 display: block;
}
.graph-height {
 height: 339.5px !important;
}
.pie-ertxt {
 text-align: center;
 top: -1em;
 position: relative;
 font-size: 15px;
}

@media (max-width: 1366px) and (min-width: 768px) {
 .js-plotly-plot {
  margin-left: 0em !important;
  margin-top: -1.6em;
 }
}

@media (max-width: 1920px) and (min-width: 1080px) {
 .js-plotly-plot {
  margin-left: 0em !important;
  margin-top: -1.6em;
 }
}
