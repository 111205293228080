/*404 page*/
h1.pnf-text {
 color: $clr-text-six;
 font-size: 9rem;
 margin: 3.125rem 0 0;
 filter: drop-shadow(0.6rem 0.6rem 0.2rem rgba(0, 0, 0, 0.7));
 white-space: nowrap;
 font-family: $font-family-montserrat;
 font-weight: $font-bold;
 height: 13.125rem;
}
@media (max-width: 30rem) {
 h1.pnf-text {
  font-size: 8.5rem;
 }
}
h1.pnf-text > span {
 -webkit-animation: spooky 2s alternate infinite linear;
 animation: spooky 2s alternate infinite linear;
 display: inline-block;
}

h2.pnf-text {
 color: $clr-text-six;
 font-size: 1.625rem;
 font-weight: $font-bold;
 font-family: $font-family-montserrat;
}

p.pnf-text {
 color: $clr-text-five;
 margin-top: 0;
 font-family: $font-family-montserrat;
}
.portfolio-section.pnf {
 height: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 padding-bottom: 0rem;
 margin-top: 0rem;
 min-height: 90vh;
}
h2.apierror.pnf {
 margin: 1em auto;
}
img.api-error.pnf {
 margin-top: 9em;
}
@-webkit-keyframes spooky {
 from {
  transform: translatey(0.15em) scaley(0.95);
 }
 to {
  transform: translatey(-0.15em);
 }
}

@keyframes spooky {
 from {
  transform: translatey(0.15em) scaley(0.95);
 }
 to {
  transform: translatey(-0.15em);
 }
}
/*404 pages*/
