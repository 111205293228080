/* publisher section */
h2.publisher-head {
 color: $clr-hyperlink;
 font-weight: $font-semibold;
 line-height: 3.6875rem;
 font-family: $font-family-montserrat;
 font-size: 3.25rem;
 margin-top: 4.2em;
}
.title.publisher-text {
 font-family: $font-family-opensans;
 font-size: 1.3125rem;
 font-weight: $font-medium;
 line-height: 2.125rem;
 text-transform: unset;
 margin-bottom: -2.5rem;
}

.publisher-border {
 border-bottom: 0.0625rem solid $clr-border-one;
}

.publisher-border-top {
 border-top: 0.0625rem solid $clr-border-one;
}
.portfolio-steps {
 background-color: $clr-bg-gray;
 margin-top: 0;
}

h2.portfolio-steps {
 color: $clr-hyperlink;
 font-weight: $font-bold;
 line-height: 2.625rem;
 font-family: $font-family-montserrat;
 font-size: 2.125rem;
 text-align: center;
}

.step-border {
 border-right: 0.0625rem solid $clr-border-one;
 height: 14.875rem;
}

h4.stephead {
 font-size: 1rem;
 font-family: $font-family-montserrat;
 color: $clr-text-three;
 line-height: 2rem;
 font-weight: $font-bold;
 text-transform: uppercase;
}

p.steptext {
 color: $clr-text-five;
 line-height: 1.5625rem;
 font-size: 0.9375rem;
 font-weight: $font-regular;
 font-family: $font-family-opensans;
 margin-top: 2.5rem;
}

h2.invest-app {
 color: $clr-hyperlink;
 font-weight: $font-bold;
 line-height: 3.5625rem;
 font-family: $font-family-montserrat;
 font-size: 2.3125rem;
 text-align: center;
}

p.invest-text {
 text-align: center;
 font-family: $font-family-opensans;
 font-size: 1.1875rem;
 color: $clr-text-three;
 line-height: 1.6875rem;
 font-weight: $font-regular;
 margin-top: 2.5rem;
}

.border-investapp {
 border-top: 0.1875rem solid $clr-hyperlink !important;
 width: 3.75rem;
 margin: 1.875rem auto;
}

h4.investapp-head {
 font-size: 1rem;
 color: $clr-text-three;
 font-family: $font-family-montserrat;
 line-height: 2rem;
 font-weight: $font-bold;
}

p.investapptext {
 color: $clr-text-five;
 font-family: $font-family-opensans;
 font-size: 0.9375rem;
 line-height: 1.5625rem;
 font-weight: $font-regular;
 margin-top: 1.25rem;
}

.investapp-mar {
 margin: 0 10em 0 0 !important;
}

.htb-section {
 background-color: #333337;
}

button.tab-head {
 color: $clr-text-three;
 line-height: 2rem;
 font-size: 1rem;
 font-weight: $font-bold;
 font-family: $font-family-montserrat;
 text-transform: uppercase;
}

button.Mui-selected.tab-head {
 color: $clr-text-three;
 line-height: 2rem;
 font-size: 1rem;
 font-weight: $font-bold;
 font-family: $font-family-montserrat;
 text-transform: uppercase;
}

.min-width-btn {
 min-width: 6.25rem !important;
}

.error-popup {
 margin: 5em 0 1em !important;
}

p.tab-text {
 color: $clr-text-five;
 line-height: 1.5625rem;
 font-size: 0.9375rem;
 font-family: $font-family-opensans;
 font-weight: $font-regular;
 margin-top: 1.25rem;
}

.MuiTabs-root.tab-indicator span.MuiTabs-indicator {
 background-color: $clr-text-three;
 height: 0.1875rem;
}

.MuiTabs-root.tab-indicator .MuiTabs-scroller.MuiTabs-fixed .MuiTabs-flexContainer {
 border-bottom: 0.1875rem solid !important;
 border-color: $clr-border-one !important;
 width: 100%;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.tab-head {
 box-shadow: none !important;
 width: 100%;
 margin: 0 auto;
}

.tabimg1 {
 margin-top: 0.625rem !important;
}

.tabimg2 {
 margin-top: -0.625rem !important;
}

.tabimg3 {
 margin-top: -0.9375rem !important;
}
/* publisher section */
