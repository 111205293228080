/* Custom styles for React-Bootstrap Tabs and Tab components */
/* Style the tab list */
.nav-link {
 color: #333 !important;
 padding: 0.5rem 1.5rem !important;
 font-size: 14px !important;
}
ul.nav.nav-tabs {
 margin: 1px auto;
 margin-bottom: -60px;
 border-bottom: 1.5px solid #3a67e1;
 overflow: hidden;
 transition: border 250ms ease;
}

li.nav-item {
 display: block;
 float: right;
 // padding: 0px 10px 0px;
 // border-radius: 10px;
 background-color: #d5defe;
 margin-right: 30px;
 margin-left: 0px;
 // margin-right: 0px;
 // margin-left: 30px;
 z-index: 2;
 position: relative;
 cursor: pointer;
 color: #777;
 text-transform: uppercase;
 transition: all 250ms ease;
 border-radius: 8px 8px 0 0;
 border: 0px solid transparent !important;
 margin-bottom: calc(-1 * 0px) !important;
}
//   .nav-link{
//     border: 0px !important;
//   }

.nav-item .nav-link.active {
 background-color: #3a67e1 !important;
 color: #fff !important;
}
.nav-item .nav-link.active::before {
 background-color: #3a67e1 !important;
 color: #fff !important;
 display: none;
}
.nav-item .nav-link.active::after {
 background-color: #3a67e1 !important;
 color: #fff !important;
 display: none;
}
.nav-item .nav-link:hover {
 background-color: #dee7ff;
 color: #444;
}
.nav-item .nav-link:hover:before {
 background-color: #dee7ff;
 color: #444;
}
.nav-item .nav-link:hover:after {
 background-color: #dee7ff;
 color: #444;
}

.nav-tabs .nav-link {
 border-radius: 8px 8px 0 0;
 border: 0px solid transparent !important;
 margin-bottom: calc(-1 * 0px) !important;
 font-weight: 500;
}

.nav-tabs .nav-link::before {
 right: -8px;
 transform: skew(30deg, 0deg);
 box-shadow: rgba(0, 0, 0, 0.1) 3px 2px 3px, inset rgba(255, 255, 255, 0.09) -1px 0;
 display: block;
 content: " ";
 position: absolute;
 top: 0;
 height: 100%;
 width: 30px;
 background-color: #f4f7f9;
 transition: all 250ms ease;
 border-radius: 0 8px 0 0;
 display: none;
}

.nav-tabs .nav-link::after {
 left: -8px;
 transform: skew(-30deg, 0deg);
 box-shadow: rgba(0, 0, 0, 0.1) -3px 2px 3px, inset rgba(255, 255, 255, 0.09) 1px 0;
 display: block;
 content: " ";
 position: absolute;
 top: 0;
 height: 100%;
 width: 30px;
 background-color: #f4f7f9;
 transition: all 250ms ease;
 border-radius: 8px 0 0 0;
 display: none;
}
button.nav-link.disabled {
 background-color: #d4d4d4;
}
.nav-tabs .nav-link.disabled::before {
 background-color: #d4d4d4;
 display: none;
}
.nav-tabs .nav-link.disabled::after {
 background-color: #d4d4d4;
}
.tab-content {
 padding: 0 0em !important;
}
