/*checkbox*/
.form-group-checkbox {
 display: block;
 margin: 0rem 0;
}

.form-group-checkbox input {
 padding: 0;
 height: initial;
 width: initial;
 margin-bottom: 0;
 display: none;
 cursor: pointer;
}

.form-group-checkbox label {
 position: relative;
 cursor: pointer;
}

.form-group-checkbox label:before {
 content: "";
 -webkit-appearance: none;
 background-color: transparent;
 border: 0.125rem solid $clr-bg-portfolio;
 box-shadow:
  0 0.0625rem 0.125rem rgba(0, 0, 0, 0.05),
  inset 0rem -0.9375rem 0.625rem -0.75rem rgba(0, 0, 0, 0.05);
 padding: 0.5rem;
 width: 1rem;
 height: 1rem;
 display: inline-block;
 position: relative;
 vertical-align: middle;
 cursor: pointer;
 border-radius: 0.125rem;
 margin-top: 0.9375rem;
}

.form-group-checkbox input:checked + label:after {
 content: "";
 display: block;
 position: absolute;
 top: 0.25rem;
 left: 0.6875rem;
 width: 0.375rem;
 height: 1.1875rem;
 border: 0.09375rem solid $clr-bg-portfolio;
 border-width: 0 0.125rem 0.125rem 0;
 transform: rotate(45deg);
 background-color: $clr-white;
}

.agreementTnC-container {
 margin: 0rem 0 !important;
}
/*/checkbox*/
