.datatable {
 border: 1px solid #e8e8f7;
 // border-bottom: 0px solid #e8e8f7;
}
.rdt_TableHeadRow {
 border-bottom-color: #e8e8f7 !important;
 min-height: 56px !important;
}
.rdt_TableBody {
 border-bottom-color: #e8e8f7 !important;
}
.rdt_TableRow:not(:last-of-type) {
 border-bottom-color: #e8e8f7 !important;
}
.rdt_TableCol {
 background-color: #ffffff !important;
}
.rdt_TableRow:hover {
 background-color: #ffffff !important;
 outline-width: 0px !important;
 outline-color: #e8e8f7 !important;
}

//summary table
.badge {
 border-radius: 8px !important;
 padding: 10px 15px !important;
 font-size: 13px !important;
 font-weight: 500 !important;
 line-height: 1.2 !important;
}
.bg-primary-transparent {
 background-color: rgba(98, 89, 202, 0.1) !important;
 color: rgba(98, 89, 202, 1) !important;
}
.bg-warning-transparent {
 background-color: rgba(255, 155, 33, 0.1) !important;
 color: rgba(255, 155, 33, 1) !important;
}
.bg-danger-transparent {
 background-color: rgba(253, 96, 116, 0.1) !important;
 color: rgba(253, 96, 116, 1) !important;
}
.bg-download-button {
 background-color: #0d6efd !important;
 color: #fff !important;
}
.btn-disable {
 opacity: 0.5;
 cursor: not-allowed !important;
}
.rdt_TableCell div {
 white-space: break-spaces !important;
 overflow: visible !important;
}
input.w-25.form-control.table-search {
 padding: 0.375rem 0.75rem;
 height: 45px;
 margin-bottom: 20px;
 box-shadow: none !important;
 border: 1px solid #e8e8f7 !important;
 font-family: "Montserrat", sans-serif !important;
 font-size: 13px;
}
select.form-select.form-select-sm {
 height: 40px;
 box-shadow: none !important;
 border: 1px solid #e8e8f7 !important;
}
table.multidata {
 border: 1px solid #e8e8f7;
 //  width: 1164px;
 height: 50px;
 border-left: 0px;
 border-right: 0;
 border-top: 0;
 font-size: 13px;
}

.datatable::-webkit-scrollbar {
 height: 0.3rem;
}

table.multidata tr td:nth-child(1) {
 padding: 0px 16px;
 min-width: 48px;
 max-width: 48px;
}

table.multidata tr td:nth-child(2) {
 padding: 0px 16px;
 min-width: 170px;
 max-width: 170px;
}

table.multidata tr td:nth-child(3) {
 padding: 0px 16px;
 min-width: 230px;
 max-width: 230px;
}

table.multidata tr td:nth-child(4) {
 min-width: 140px;
 max-width: 140px;
 padding: 0px 16px;
}

table.multidata tr td:nth-child(5) {
 min-width: 160px;
 max-width: 160px;
 padding: 0px 16px;
}

table.multidata tr td:nth-child(6) {
 padding: 0px 16px;
 max-width: 470px;
 min-width: 470px;
}
