.app-header {
 max-width: 100%;
 height: 4rem;
 z-index: 100;
 position: fixed;
 inset-block-start: 0;
 inset-inline: 0;
 background: #fff;
 border-block-end: 1px solid #e1e6f1;
 box-shadow: -7.829px 11.607px 20px 0 rgba(144, 143, 160, 0.09);
 transition: all 0.1s ease;
 max-width: 1920px;
 margin: 0 auto;

 .main-header-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  flex-direction: row-reverse;
 }

 // Extend from dropdown
 // dropdown
 .dropdown-menu {
  padding: 0.7rem 0rem;
  font-size: 0.875rem;
  line-height: 22px;
  color: #5c5776;
  border: none;
  box-shadow: 0 10px 30px 0 rgba(31, 45, 61, 0.1);
  border-radius: 0;

  i {
   margin-right: 3px;
   color: #2a242e;
   font-size: 18px;
  }

  a.dropdown-item {
   color: #2a242e;
   text-decoration: none;
  }

  display: block;
  visibility: hidden;
  opacity: 0;
  width: 200px;
  padding: 10px;
  top: 58px;
  right: 0px;
  background-color: #fff;
  transform: translateY(20px);
  transition: all 0.3s ease-in;

  &:hover {
   transform: scaleY(1);
   opacity: 1;
   visibility: visible;
  }
 }

 .dropdown {
  &:hover {
   > .dropdown-menu {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
   }
  }
 }

 .dropdown-submenu {
  &:hover {
   > .dropdown-menu {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
   }
  }
 }

 @media (min-width: 990px) {
  .dropright-lg {
   position: relative;

   .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-right: 0.125rem;
   }
  }
 }
}

.avatar-md {
 width: 56px;
 height: 56px;
}

.avatar-indicators:before {
 content: "";
 position: absolute;
 bottom: 0px;
 right: 5%;
 width: 30%;
 height: 30%;
 border-radius: 50%;
 border: 2px solid #fff;
 display: table;
}

a#navbarDropdownMenuLink {
 width: 52px;
 height: 52px;
 // background-color: #dee7ff;
 display: flex;
 border-radius: 50px;
 font-size: 32px !important;
 font-weight: 700;
 text-decoration: none;
 color: #ef365c !important;
 top: -6px;
 position: relative;
 // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

 span {
  left: -14px;
  position: absolute;
 }
}
.dropdown-toggle::after {
 display: inline-block;
 margin-left: 0.255em;
 vertical-align: 0.255em;
 content: "";
 border-top: 0.3em solid;
 border-right: 0.3em solid transparent;
 border-bottom: 0;
 border-left: 0.3em solid transparent;
 top: 19.5px;
 left: 8px;
 position: relative;
 font-size: 21px;
}
li.nav-item.signout.dropdown {
 background-color: #ffffff;
}
li.nav-item.signout.dropdown a#navbarDropdownMenuLink:hover {
 background-color: #ffffff;
}
a#navbarDropdownMenuLink:hover::after {
 color: #ef365c !important;
}
a#navbarDropdownMenuLink::after {
 background-color: #ffffff;
}
.dropdown-item {
 text-transform: capitalize;
}
span.firstalpha {
 background: -webkit-linear-gradient(#fccaca, #f70000);
 border-radius: 50px;
 padding: 0 12px;
 color: #fff;
 font-size: 26px;
 font-weight: 600;
 margin-top: 3px;
}
