.main-loader {
 // font-family: "Ubuntu", sans-serif;
 display: flex;
 align-items: center;
 justify-content: center;
 height: 100vh;
 overflow: hidden;
 margin: 0;

 // .bg {
 //   background: url("../../images/bg.svg") no-repeat center center/cover;
 //   position: absolute;
 //   top: -30px;
 //   left: -30px;
 //   width: calc(100vh + 60px);
 //   height: calc(100vh + 60px);
 //   z-index: -1;
 //   filter: blur(0px);
 // }

 .loading-text {
  color: #000;
  font-size: 50px;
  font-weight: 600;
 }
}
