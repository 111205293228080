/* Accordion styles */
input.faq {
 position: absolute;
 opacity: 0;
 z-index: -1;
}

.row.faq {
 display: flex;
}
.row.faq .col {
 flex: 1;
}

.tabs {
 border-radius: 8px;
 overflow: hidden;
 box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.tab {
 width: 100%;
 color: $clr-text-three;
 overflow: hidden;
}
.tab-label {
 display: flex;
 justify-content: space-between;
 padding: 1em;
 background: $clr-bg-dashboard;
 font-weight: bold;
 cursor: pointer;
 /* Icon */
}
.tab-label:hover {
 background: $clr-bg-dashboardmenu;
}
.tab-label::after {
 content: "❯";
 width: 1em;
 height: 1em;
 text-align: center;
 transition: all 0.35s;
}
.tab-content {
 max-height: 0;
 padding: 0 1em;
 color: #2c3e50;
 background: white;
 transition: all 0.35s;
}
.tab-close {
 display: flex;
 justify-content: flex-end;
 padding: 1em;
 font-size: 0.75em;
 background: $clr-bg-dashboard;
 cursor: pointer;
}
.tab-close:hover {
 background: $clr-bg-dashboardmenu;
}

input:checked + .tab-label {
 background: $clr-bg-dashboard;
}
input:checked + .tab-label::after {
 transform: rotate(90deg);
}
input:checked ~ .tab-content {
 max-height: 100vh;
 padding: 1em;
}
h2.faq-heading {
 color: $clr-text-three;
 font-family:
  Open Sans,
  sans-serif;
 font-size: 1.375rem;
 font-weight: 700;
 line-height: 1.875rem;
 text-align: left;
 margin: 3.5rem 0 1.5rem;
}
.faqtop {
 margin-top: 8.5em !important;
}
