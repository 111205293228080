/* search section */
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.advisorsearch {
 margin-left: -3.5rem;
 // margin-bottom: -1.25rem;
}
button.search-icon:hover {
 background-color: transparent;
 box-shadow: none;
 cursor: pointer;
}
span.icon.fa.fa-search:hover {
 color: $clr-bg-main;
}
.portright {
 float: right;
}
input.search-portfolio::placeholder {
 font-weight: $font-bold;
 font-size: 1rem;
 font-family: $font-family-opensans;
 color: $clr-pink;
 line-height: 2.5rem;
}
input.search-portfolio {
 border-radius: 1.25rem;
 height: 2.1875rem;
 margin-left: 2.8125rem;
 margin-top: 2.5rem;
 padding: 0 0.9375rem;
 font-size: 0.9375rem;
 font-weight: $font-bold;
 color: $clr-bg-portfolio;
 font-family:
  Open Sans,
  sans-serif;
}

button.search-icon {
 background: $clr-white;
 font-size: 1rem;
 position: absolute;
 display: block;
 /* left: 86.5%; */
 margin-left: 15em;
}

span.icon.fa.fa-search {
 color: $clr-text-seven;
 position: absolute;
 top: -1.5625rem;
}
button.search-icon.advisorsearch {
 background: $clr-white;
 font-size: 1rem;
 position: absolute;
 display: block;
 /* left: 84%; */
}
