// add delete button
.main-hr {
 width: 30%;
 border: none;
 border-top: 1px solid #c3c3c3;
}
.icon-btn {
 width: 50px;
 height: 50px;
 border: 0px solid #cdcdcd;
 background: white;
 border-radius: 8px;
 overflow: hidden;
 position: relative;
 margin-right: 8px;
 transition: width 0.2s ease-in-out;
 box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.add-btn:hover {
 width: 120px;
 background-color: #ffffff;
 box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
 border-radius: 8px;
}
.add-btn::before,
.add-btn::after {
 transition: width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
 content: "";
 position: absolute;
 height: 4px;
 width: 10px;
 top: calc(50% - 2px);
 background: #3a67e1;
}
.add-btn::after {
 right: 14px;
 overflow: hidden;
 border-top-right-radius: 2px;
 border-bottom-right-radius: 2px;
}
.add-btn::before {
 left: 14px;
 border-top-left-radius: 2px;
 border-bottom-left-radius: 2px;
}
.icon-btn:focus {
 outline: none;
}
.btn-txt {
 opacity: 0;
 transition: opacity 0.2s;
 font-size: 15px;
 font-weight: 400;
}
.add-btn:hover::before,
.add-btn:hover::after {
 width: 4px;
 border-radius: 2px;
}
.add-btn:hover .btn-txt {
 opacity: 1;
 color: #3a67e1;
}
.add-icon::after,
.add-icon::before {
 transition: all 0.2s ease-in-out;
 content: "";
 position: absolute;
 height: 20px;
 width: 2.5px;
 top: calc(50% - 10px);
 background: #3a67e1;
 overflow: hidden;
}
.add-icon::before {
 left: 22px;
 border-top-left-radius: 2px;
 border-bottom-left-radius: 2px;
}
.add-icon::after {
 right: 22px;
 border-top-right-radius: 2px;
 border-bottom-right-radius: 2px;
}
.add-btn:hover .add-icon::before {
 left: 15px;
 height: 4px;
 top: calc(50% - 2px);
}
.add-btn:hover .add-icon::after {
 right: 15px;
 height: 4px;
 top: calc(50% - 2px);
}
.btn-mt {
 margin-top: 43px;
}
// add delete button

/* file upload */
input.upload {
 color: rgba(0, 0, 0, 0.6);
 font-weight: 400;
 font-size: 14px;
 cursor: pointer;
}

input[type="file"]::-webkit-file-upload-button {
 border: 0px;
 padding: 6px 10px;
 border-radius: 8px;
 background-color: #3a67e1;
 color: #fff;
 transition: 1s;
 margin-top: 3px;
 margin-left: -5px;
 cursor: pointer;
 font-size: 14px;
}
.ml-40 {
 margin-left: 36px;
}
.plr27 {
 padding-left: 0.5rem;
 padding-right: 2.7rem;
}
.mt27 {
 margin-top: 2.7rem !important;
}
.ques {
 display: inline-flex;
 margin-left: -4.5em !important;
 position: absolute;
}
.checkbox {
 box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
 border: 0px !important;
 height: 50px;
 border-radius: 8px !important;
 padding: 14px 20px 10px 40px;
 margin: 12px;
 width: 195px !important;
}
.form-check .form-check-input {
 margin-left: 0.5em !important;
}
label.form-check-label {
 margin-left: 10px;
}
input.form-control.qheight {
 height: 39px;
}
.spinner-border {
 width: 17px !important;
 height: 17px !important;
 margin-left: 8px;
}
@media screen and (max-width: 1366px) {
 .checkbox {
  width: 165px !important;
 }
}
