/* colors */
$clr-white: #ffffff;
$clr-black: #010101;
$clr-pink: #ef365c;
$clr-green: #00c7ba;
$clr-blue: #3a67e1;
$clr-red: #eb0000;
$clr-error: #d32f2f;
$clr-success: #0dab03;
$clr-hyperlink: #1673fd;

/* text colors */
$clr-text-one: #f3f3f3;
$clr-text-two: #e9e7e7;
$clr-text-three: #d4d4d4;
$clr-text-four: #d1d6d6;
$clr-text-five: #cccccc;
$clr-text-six: #e7ebf2;
$clr-text-seven: #959191;
$clr-text-eight: #4e4d4d;
$clr-text-nine: #969595;

/* border colors */
$clr-border-one: #707070;
$clr-border-two: #8f8c8c;
$clr-border-three: #767474;

/* background colors*/
$clr-bg-gray-dark: #373339;
$clr-bg-gray: #3d3d3e;
$clr-bg-main: #2a242e;
$clr-bg-advisor: #605f5f;
$clr-bg-portfolio: #434343;
$clr-bg-button-disabled: #8b8b8b;
$clr-bg-dashboardmenu: #211f22;
$clr-bg-dashboard: #342f3b;
$clr-bg-scrollbar: #c2c2c2;
$clr-bg-scrollbar1: #a09d9d;

/* gradient color */
$clr-hr: linear-gradient(90deg, #ee365c 0%, 31.3793%, #1c71f8 62.7586%, 81.3793%, #434343 100%);
$clr-mr: linear-gradient(90deg, #1673fd 34.5751%, 51.5404%, #03c2b8 68.5057%, 84.2529%, #434343 100%);
$clr-lr: linear-gradient(90deg, #00c78b 0%, 14.023%, #03c2b7 28.046%, 64.023%, #434343 100%);

$clr-hrd: linear-gradient(90deg, #ee365c 17.8114%, 43.8882%, #1c71f8 69.9651%, 84.9825%, #2a242e 99.5%);
$clr-mrd: linear-gradient(90deg, #1673fd 8.8114%, 25.8882%, #03c2b8 69.9651%, 84.9825%, #2a242e 99.5%);
$clr-lrd: linear-gradient(90deg, #00c78b 20.8114%, 40.8882%, #03c2b7 70.046%, 85.023%, #2a242e 99.5%);

$clr-portfoliodetails: transparent linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(22, 115, 253, 0.19) 100%);

/* box shadow colors */
$clr-box-shadow:
 rgba(0, 0, 0, 0.07) 0rem 0rem 0rem,
 rgba(0, 0, 0, 0.07) 0rem 0rem 0rem,
 rgba(0, 0, 0, 0.07) 0rem 0.25rem 0.25rem,
 rgba(0, 0, 0, 0.07) 0rem 0.0625rem 0.1875rem,
 rgba(0, 0, 0, 0.07) 0rem 0rem 0.25rem;
$clr-top-box-shadow: 0px -8px 10px -12px rgba(0, 0, 0, 0.7);

/* hover colors */
$clr-box-hover: #313131;

/* font family */
$font-family-opensans: "Montserrat", sans-serif !important;
$font-family-montserrat: "Montserrat", sans-serif;

/*font weight*/
$font-xlight: 200;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-xbold: 800;
$font-black: 900;
