$scrollbar-width: 3px;
$scrollbar-track-shadow: inset 0 0 5px grey;
$scrollbar-thumb-background: #333;

* {
 margin: 0;
 padding: 0;
}

ul {
 padding: 0 !important;
 margin: 0;
}

li {
 list-style: none;
}

body {
 background-color: #eee;
 font-family: "Montserrat", sans-serif !important;
}

/* width */
::-webkit-scrollbar {
 width: $scrollbar-width;
}

/* Track */
::-webkit-scrollbar-track {
 box-shadow: $scrollbar-track-shadow;
 border-radius: 10px;
 display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
 background: $scrollbar-thumb-background;
 border-radius: 10px;
 display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
 background: $scrollbar-thumb-background;
 display: block;
}

.side-menu-dashboard {
 position: fixed;
 background: #2a242e;
 width: 15.4rem;
 height: 105%;
 box-sizing: border-box;
 padding: 10px 0px 10px 10px;
 transition: all 200ms ease;
 z-index: 103;
 overflow-y: scroll;

 &.inactive {
  width: 80px;

  .top-section .toggle-menu-btn {
   top: -0.8em;
   height: 20px;
  }

  .main-menu-dashboard .menu-item span {
   opacity: 1;
   height: 0;
   overflow: visible;
  }

  .side-menu-dashboard-footer .user-info {
   opacity: 1;
   width: 0;
   height: 0;
   overflow: hidden;
  }
 }

 .top-section {
  position: relative;
  height: 36px;

  .logo {
   overflow: hidden;
   display: flex;
   margin: 5px;
   width: 55%;

   img {
    max-width: 100%;
    max-height: 100%;
   }
  }

  .toggle-menu-btn {
   color: #ffffff;
   font-size: 28px;
   display: flex;
   position: absolute;
   top: 100%;
   left: 85%;
   transform: translateY(-85%);
   cursor: pointer;
   justify-content: flex-end;
  }
 }

 .search-controller {
  color: #fff;
  position: relative;

  .search-btn {
   width: 40px;
   height: 35px;
   position: absolute;
   background: transparent;
   border: 0;
   font-size: 20px;
   color: #666;
  }

  input[type="text"] {
   border: 0;
   outline: none;
   height: 35px;
   background: #333;
   border-radius: 5px;
   display: block;
   margin: 20px 0;
   width: 100%;
   box-sizing: border-box;
   padding-left: 40px;
   color: #666;
  }
 }

 .main-menu-dashboard {
  margin: 16px 0px;
  position: relative;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  .menu-item {
   color: #ccc;
   text-decoration: none;
   font-size: 15px;
   display: block;
   font-weight: 600;
   cursor: pointer;
   padding: 5px 10px;

   &.active {
    background: linear-gradient(90deg, #00c78b, 14.023%, #03c2b7 28.046%, 64.023%, #2a242e);
    border-radius: 30px 0 0px 30px;
    color: #fff;
   }

   .menu-icon {
    display: inline-block;
    // width: 50px;
    height: 41px;
    font-size: 24px;
    top: 3px;
    position: relative;
    left: 14px;
   }

   span {
    position: absolute;
    display: inline-block;
    line-height: 41px;
    opacity: 1;
    transition: opacity 0.2s ease-in;
    margin-left: 35px;
   }

   &:hover {
    span {
     opacity: 1;
    }
   }
  }

  .sub-menu {
   color: #333;
   margin-left: 14px;
   box-sizing: border-box;
   padding-left: 0px;
   max-height: 0;
   overflow: hidden;
   transition: max-height 0.2s ease-in;

   &.active {
    max-height: 200px;
   }

   a {
    display: block;
    margin: 5px 0;
    font-size: 13.5px;
    font-weight: 500;
    text-decoration: none;
    color: #ccc;
    box-sizing: border-box;
    padding: 5px;

    &.active {
     background: #204c50;
     display: block;
     border-radius: 0px;
     color: #fff;
     border-top-left-radius: 15px;
     border-bottom-left-radius: 15px;
    }
   }
  }
 }

 .side-menu-dashboard-footer {
  width: 100%;
  background: #333;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  box-sizing: border-box;

  .avatar {
   width: 40px;
   height: 40px;
   overflow: hidden;
   border-radius: 50%;
   display: inline-block;

   img {
    max-width: 100%;
   }
  }

  .user-info {
   display: inline-block;
   margin: 0 10px;
   color: #fff;
   position: absolute;
   opacity: 1;
   transition: opacity 0.2s ease-in;

   h5 {
    font-size: 15px;
   }

   p {
    font-size: 14px;
   }
  }

  &.inactive {
   .user-info {
    opacity: 1;
    width: 0;
    height: 0;
    overflow: hidden;
   }
  }
 }

 .divider {
  width: 98%;
  height: 1px;
  background: #707070;
  margin: 16px 0px 20px -4px;
 }
}
.side-menu-dashboard.inactive .main-menu-dashboard .sub-menu.active {
 max-height: 0px;
}

.container {
 margin-left: 240px;
 transition: margin-left 0.2s ease-in;
 padding: 30px;
 box-sizing: border-box;
 height: 100vh;
 background-color: rgba(234, 237, 247, 0.08);
 top: 4em;
 position: relative;

 &.inactive {
  margin-left: 80px;
 }
}

.side-menu-dashboard.inactive:hover {
 width: 80px;
}

.side-menu-dashboard.inactive .main-menu-dashboard .menu-item span:hover {
 opacity: 1 !important;
}

.side-menu-dashboard .main-menu-dashboard .menu-item span:hover {
 position: relative !important;
 display: inline !important;
 line-height: 40px;
 opacity: 1;
 transition: opacity 0.2s ease-in;
}

i.bi.bi-chevron-double-right {
 color: #fff;
 font-size: 12px;
 margin: 0px 5px 0 10px;
}
.bi-text-left::before {
 content: "\f5c7";
 margin-top: 3rem;
 margin-left: -1.3em;
}
.side-menu-dashboard.inactive .logo {
 display: none;
}
