.overlay {
 position: fixed;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
 display: flex;
 justify-content: center;
 align-items: center;
 z-index: 1000; /* Ensure it's above other content */
}
.modal-content {
 position: relative;
 display: flex;
 flex-direction: column;
 width: 600px !important;
 color: #000 !important;
 pointer-events: auto;
 background-color: #fff !important;
 background-clip: padding-box;
 border-radius: 10px !important;
 outline: 0;
 padding: 3em 5em !important;
}
.modal {
 background-color: #fff;
 border-radius: 8px;
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
 //  max-width: 600px; /* Set maximum width */
 //  width: 90%; /* Adjust width as needed */
}
.modal-header {
 padding: 20px;
 border-bottom: 1px solid #ddd; /* Add separator line */
 display: flex;
 justify-content: space-between;
 align-items: center;
}

.modal-title {
 font-size: 18px;
}

.close-button {
 cursor: pointer;
 color: #999;
 font-size: 24px;
}
.modal-body {
 padding: 20px;
 font-size: 18px;
 color: #333;
 text-align: center;
 font-weight: 600;
}
.button {
 padding: 10px 20px;
 margin-right: 10px;
 border: none;
 border-radius: 4px;
 background-color: #007bff; /* Blue color */
 color: #fff;
 cursor: pointer;
}

.button:hover {
 background-color: #0056b3; /* Darker blue color on hover */
}
@keyframes fadeIn {
 from {
  opacity: 0;
  transform: translateY(-20px);
 }
 to {
  opacity: 1;
  transform: translateY(0);
 }
}

.modal {
 animation: fadeIn 0.3s ease-out;
}
