.form-floating > .form-control {
 padding: 1.6rem 0.75rem 0rem !important;
 height: calc(3.2rem + calc(var(--bs-border-width) * 2)) !important;
 min-height: calc(3.2rem + calc(var(--bs-border-width) * 2)) !important;
 font-size: 15px !important;
}
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
 // inset: 0.6rem 0.375rem !important;
 // height: 1.3em !important;
 background-color: #ebebeb !important;
}
.form-floating > .form-select {
 padding: 1.6rem 0.75rem 0rem !important;
 height: calc(3.2rem + calc(var(--bs-border-width) * 2)) !important;
 min-height: calc(3.2rem + calc(var(--bs-border-width) * 2)) !important;
 font-size: 14px !important;
}
.form-floating {
 position: relative;
 display: grid;
}
.form-floating label {
 position: absolute;
 top: 0;
 pointer-events: none;
 transition: 0.2s ease-out;
 opacity: 0.7;
 left: 8px !important;
 // padding: 0.6rem 0.75rem !important;
 font-size: 14px !important;
}
.form-control:focus ~ label,
.form-control:not(:placeholder-shown) ~ label {
 transform: translateY(-125%) scale(0.85);
 opacity: 1;
 //   color: transparent;
}
.form-control:focus {
 color: #212529;
 background-color: #ffffff;
 border-color: #0d6efd !important;
 outline: 0;
 box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25) !important;
}
.form-select:focus {
 color: #212529;
 background-color: #ffffff;
 border-color: #0d6efd !important;
 outline: 0;
 box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25) !important;
}
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
 color: #000 !important;
 opacity: 1;
}
select {
 padding: 10px; /* Padding for the select box itself */
}
option {
 padding: 5px; /* Padding for each option */
}
textarea.ant-input {
 border-bottom-left-radius: 8px;
 border-bottom-right-radius: 8px;
 box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
 border: 0px !important;
 padding: 20px 15px;
 font-size: 16px;
 font-weight: 500;
 font-family: "Montserrat", sans-serif !important;
}
img.uploadimg {
 border: 1px solid rgba(0, 0, 0, 0.26);
 width: 60px;
 height: 50px;
 border-radius: 8px;
 box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 8px -13px;
 margin-top: 34px;
}
.ant-picker-input {
 margin-top: 3px;
}
.exp-date .ant-picker-input {
 margin-top: 8px;
}
textarea.ant-input::placeholder {
 color: #666666;
}
i.bi-x {
 font-size: 28px;
 margin: 0 5px;
}
.agreementPreviewBackend {
 text-align: center;
 font-weight: 500;
 font-size: 20px;
 margin: 40px 0 60px;
}
.bgimg {
 background-color: #3a67e1 !important;
 border-top-left-radius: 0.375rem;
 border-bottom-left-radius: 0.375rem;
}
.login-panel {
 padding: 10px 0 10px 25px !important;
}
input.form-control.width90 {
 width: 90%;
}
.width90 {
 width: 90% !important;
}
// .sign-font {
//  font-family: "Fugaz One", sans-serif;
// }
img.sign-logo {
 width: 80%;
 margin-right: 25px;
}
.signin-center {
 top: 35%;
 left: 50%;
 -ms-transform: translate(-50%, -50%);
 transform: translate(0%, 35%);
}
.hv-center {
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.cp-center {
 position: absolute;
 top: 45%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.error {
 color: #d90404;
 font-size: 12px;
 margin: 5px 0;
 width: 100%;
}
button.btn1.btn-primary.btn-1:disabled {
 background-color: #a2a1a1;
}
h2.resetpass {
 color: #010101;
 font-family: "Montserrat", sans-serif;
 font-size: 16px;
 font-weight: 400;
 margin-top: 0px;
 margin-bottom: 30px;
 margin-right: 20px;
 line-height: 1.5;
 font-weight: 500;
}
.resetp {
 position: absolute;
 top: 45%;
 left: 20%;
 transform: translate(-50%, -50%);
 width: 35%;
}
.modal-main-front.popup-center {
 left: 59%;
}
.msg-icon {
 position: absolute;
}
.msg-text {
 margin-left: 1.7em;
 font-weight: 400;
 font-size: 15px;
 text-align: left;
}
.dropdown-item.active,
.dropdown-item:active {
 background-color: #f8f9fa !important;
}
